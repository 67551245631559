import React from "react";

import Container from "components/Container";

import triangleBackground from "assets/triangle.svg";
import heroImage from "assets/hero-image.svg";
import heroImageMobile from "assets/hero-image-mobile.svg";

const Intro = () => {
  return (
    <Container
      className="flex flex-col-reverse bg-no-repeat bg-right-top bg-16 md:bg-32 lg:flex-row lg:items-center lg:bg-left lg:bg-contain"
      style={{ backgroundImage: `url(${triangleBackground})` }}
    >
      <div className="flex-1 my-8 lg:mt-4">
        <div className="text-left">
          <h2 className="font-header tracking-tight leading-tight font-extrabold text-purple-600 text-4xl sm:text-5xl">
            Make productivity
            <br />a habit.
          </h2>
          <p className="mt-4 text-base leading-tight text-purple-400 font-semibold sm:mt-5 sm:text-lg md:mt-8 md:text-2xl">
            Tidbit develops heathier digital habits
            <br className="hidden lg:block" />
            with smart nudges, progressive
            <br className="hidden lg:block" />
            adjustments, and key analytics.
          </p>
          <p className="mt-4 text-sm font-medium leading-snug text-gray-700 sm:mt-5 sm:max-w-xl md:text-base md:mt-8">
            We’re launching on Chrome and Firefox soon!
            <br />
            Drop your email to stay updated.
          </p>
          <form
            action="https://site.us18.list-manage.com/subscribe/post?u=cdaa90443ac14bdeee6c2d751&amp;id=02ebdb02ec"
            className="mt-4 sm:mt-5 sm:flex lg:justify-start"
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
            novalidate
          >
            <input
              type="email"
              value=""
              name="EMAIL"
              className="w-full bg-white py-2 px-4 border border-gray-600 rounded-md block appearance-none leading-normal sm:w-auto sm:py-3 sm:px-4 focus:outline-none focus:shadow-outline"
              placeholder="Email Address"
              required
            />
            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_cdaa90443ac14bdeee6c2d751_02ebdb02ec"
                tabindex="-1"
                value=""
              />
            </div>
            <div className="mt-2 sm:ml-3 sm:mt-0">
              <input
                type="submit"
                value="Submit"
                name="subscribe"
                className="w-full flex items-center justify-center px-8 py-2 border border-transparent shadow-md text-base font-medium rounded-md text-white bg-purple-600 cursor-pointer sm:py-3 hover:text-purple-100 hover:bg-purple-700 focus:outline-none focus:shadow-outline"
              />
            </div>
          </form>
        </div>
      </div>

      <div className="flex-1 self-start mt-8 lg:mt-0 lg:self-auto">
        <img className="hidden lg:block" src={heroImage} alt="hero" />
        <img
          className="h-40 w-full object-contain sm:h-56 lg:hidden"
          src={heroImageMobile}
          alt="hero"
        />
      </div>
    </Container>
  );
};

export default Intro;
